html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ol,
ul,
li {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("assets/fonts/SVN-Poppins-Bold.ttf")
      format("opentype");
  font-weight: bold;
  font-style: bold;
}
.infinite-scroll-component__outerdiv {
  height: 100%;
  overflow: auto;
}
.infinite-scroll-component {
  /* overflow: unset !important; */
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("assets/fonts/SVN-Poppins-Regular.ttf")
      format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("assets/fonts/SVN-Poppins-Light.ttf")
      format("opentype");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url("assets/fonts/SVN-Poppins-Light.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url("assets/fonts/SVN-Poppins-Medium.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("assets/fonts/SVN-Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("assets/fonts/SVN-Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: 600;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("assets/fonts/SVN-Poppins-SemiBold.ttf") format("truetype");
  font-weight: 700;
  font-style: 700;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("assets/fonts/SVN-Poppins-Bold.ttf") format("truetype");
  font-weight: 800;
  font-style: 800;
}
/* HTML5 display-role reset for older browsers */
body {
  line-height: 1;
  background-color: #212121;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(142 135 135);
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(66, 66, 66);
}

ol,
ul {
  list-style: none;
}

blockquote:before,
blockquote:after,
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.react-datepicker-popper {
  z-index: 4 !important;
}
#popupWarningForceSell .MuiDialog-paper {
  overflow: visible !important;
}
